<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <template v-for="field in localFieldConfigList">
      <div
        v-if="!field.valueRange.length && field.isVisible"
        :key="field.guid"
        class="d-flex align-end"
      >
        <v-text-field
          :label="field.fieldAlias"
          hide-details="auto"
          v-model="localPersonInfo[field.fieldName]"
          :rules="buildTextFieldRules(field, true)"
          :prefix="
            field.fieldName === 'testeeNum'
              ? buildTesteeNumPrefix(localPersonInfo.testeeNumPrefix)
              : ''
          "
          :disabled="
            !isCreatingNew &&
              (localDisabledFields.includes(field.fieldName) ||
                securedFieldNameList.includes(field.fieldName))
          "
        >
        </v-text-field>
        <AppTooltipBtn
          v-if="
            !isCreatingNew && securedFieldNameList.includes(field.fieldName)
          "
          color="primary"
          icon="mdi-pencil"
          :tooltip="`编辑${field.fieldAlias}`"
          @click="startEditOneSecuredField(field)"
        />
      </div>
      <v-select
        v-if="!!field.valueRange.length && field.isVisible"
        :key="field.guid"
        :label="field.fieldAlias"
        hide-details="auto"
        :items="field.valueRange"
        v-model="localPersonInfo[field.fieldName]"
        :rules="field.isRequired ? fieldRules.required : []"
        :disabled="
          !isCreatingNew &&
            (localDisabledFields.includes(field.fieldName) ||
              securedFieldNameList.includes(field.fieldName))
        "
        @change="
          personFieldChanged(field.fieldName, localPersonInfo[field.fieldName])
        "
      ></v-select>
    </template>
    <AppDialog
      v-model="isShowSecuredInfoEditDialog"
      persistent
      size="small"
      :title="`编辑${editingSecuredField.fieldAlias}`"
      color="primary"
      action-text="确定修改"
      @confirm="
        confirmEditOneSecuredField(
          editingSecuredField.fieldName,
          editingSecuredFieldValue
        )
      "
      @closed="closeEditOneSecuredField"
    >
      <v-text-field
        v-model="editingSecuredFieldValue"
        :label="editingSecuredField.fieldAlias"
        :rules="buildTextFieldRules(editingSecuredField, false)"
      ></v-text-field>
    </AppDialog>
    <AppMessageBox title="发生错误" v-model="errorMsg" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppDialog from "@/components/AppDialog";
import AppMessageBox from "@/components/AppMessageBox";
import { getUserFieldConfig, getTestGroupFieldConfig } from "@/api/fieldConfig";
import { fetchDeptList, fetchDeptListFromGroup } from "@/api/dept";
import _ from "lodash";

export default {
  components: {
    AppLoadingSpinner,
    AppTooltipBtn,
    AppDialog,
    AppMessageBox
  },

  props: {
    isCreatingNew: {
      type: Boolean,
      default: false
    },
    groupGuid: {
      type: String
    },
    userEntity: {
      type: String
    },
    userGuid: {
      type: String
    },
    personInfo: {
      type: Object,
      required: true
    },
    disabledFields: {
      type: Array
    },
    noUpdateFields: {
      type: Array
    },
    fieldConfigList: {
      type: Array
    },
    deptList: {
      type: Array
    }
  },

  model: {
    prop: "personInfo",
    event: "changed"
  },

  data() {
    return {
      isLoading: false,
      localPersonInfo: this.personInfo,
      localDisabledFields: this.disabledFields || [],
      localNoUpdateFields: this.noUpdateFields || [],
      localFieldConfigList: this.fieldConfigList || [],
      localDeptList: this.deptList || [],
      testeeNumPrefix: "",
      fieldRules: {
        required: [val => (`${val}` || "").length > 0 || "必填"],
        age: [val => /^\d{1,3}$/.test(val) || "请输入正确的年龄"]
      },
      // dialog
      isShowSecuredInfoEditDialog: false,
      editingSecuredField: {},
      editingSecuredFieldValue: "",
      errorMsg: ""
    };
  },

  watch: {
    personInfo: {
      handler(newVal) {
        this.localPersonInfo = newVal;
      },
      immediate: true,
      deep: true
    },
    localPersonInfo: {
      handler(newVal) {
        this.$emit("changed", newVal);
      },
      immediate: true,
      deep: true
    },
    "localPersonInfo.deptGuid": {
      handler(newVal) {
        this.personFieldChanged("deptGuid", newVal);
      },
      immediate: true
    },
    noUpdateFields(newVal) {
      this.localNoUpdateFields = newVal;
    },
    localNoUpdateFields(newVal) {
      this.$emit("update:no-update-fields", newVal);
    },
    fieldConfigList(newVal) {
      this.localFieldConfigList = newVal;
    },
    deptList(newVal) {
      this.localDeptList = newVal;
    }
  },

  computed: {
    securedFieldNameList() {
      let securedFields = this.localFieldConfigList.filter(
        fc => fc.rulesIsSecured
      );
      return securedFields.map(sf => sf.fieldName);
    }
  },

  methods: {
    isDeptListFound(deptList) {
      return deptList && deptList.length;
    },
    buildTesteeNumPrefix(prefix) {
      return prefix ? `${prefix}-` : "";
    },
    buildTextFieldRules(fieldObj, ignoreSecured) {
      let ruleRegex = [
        val => {
          // 对于 secured 的字段，由于会加星号，所以不校验
          if (
            (!this.isCreatingNew && ignoreSecured && fieldObj.rulesIsSecured) ||
            !fieldObj.rulesRegex
          ) {
            return true;
          }
          let regPattern = new RegExp(fieldObj.rulesRegex);
          return regPattern.test(val) || fieldObj.rulesMessage;
        }
      ];
      if (fieldObj.isRequired) {
        return [...this.fieldRules.required, ...ruleRegex];
      }
      return ruleRegex;
    },
    startEditOneSecuredField(editingField) {
      this.editingSecuredField = editingField;
      this.isShowSecuredInfoEditDialog = true;
    },
    closeEditOneSecuredField() {
      this.isShowSecuredInfoEditDialog = false;
      this.editingSecuredFieldValue = "";
    },
    confirmEditOneSecuredField(editingFieldName, newVal) {
      this.localPersonInfo[editingFieldName] = newVal;
      // 修改过的 securedField，就可以保存了
      _.pull(this.localNoUpdateFields, editingFieldName);
      this.closeEditOneSecuredField();
    },
    async getFieldConfig() {
      try {
        this.isLoading = true;
        if (this.userGuid) {
          this.localFieldConfigList = await getUserFieldConfig(
            this.userEntity,
            this.userGuid
          );
        } else if (this.groupGuid) {
          this.localFieldConfigList = await getTestGroupFieldConfig(
            this.groupGuid
          );
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async getlocalDeptList() {
      try {
        this.isLoading = true;
        if (this.userGuid) {
          this.localDeptList = await fetchDeptList(
            this.userEntity,
            this.userGuid
          );
        } else if (this.groupGuid) {
          this.localDeptList = await fetchDeptListFromGroup(this.groupGuid);
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    prepareFieldConfig() {
      if (!this.localFieldConfigList || !this.localFieldConfigList.length) {
        return this.getFieldConfig();
      }
    },
    preparelocalDeptList() {
      if (!this.localDeptList || !this.localDeptList.length) {
        return this.getlocalDeptList();
      }
    },
    async personFieldChanged(fieldName, newVal) {
      if (fieldName === "deptGuid") {
        let dept = this.localDeptList.find(d => d.value === newVal);
        if (dept) {
          this.localPersonInfo.deptName = dept.text;
          this.localPersonInfo.testeeNumPrefix = dept.valueEx;
        } else {
          this.localPersonInfo.deptName = "";
          this.localPersonInfo.testeeNumPrefix = "";
        }
      }
    }
  },

  async created() {
    // 需要先加载 fieldConfig
    await this.prepareFieldConfig();
    // 初始状态下，secured 的 fields 就是不需要 update 的 fields
    this.localNoUpdateFields = [...this.securedFieldNameList];
    // DeptSelectList
    await this.preparelocalDeptList();
    if (this.isDeptListFound(this.localDeptList)) {
      let deptConfig = this.localFieldConfigList.find(
        fc => fc.fieldName === "deptGuid"
      );
      deptConfig.valueRange = this.localDeptList;
    }
  }
};
</script>
