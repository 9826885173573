import { get, post } from "@/utils/axios";

export function fetchTestPersonList(userEntity, userGuid) {
  return post("/testperson/TestPersonList", {
    entityType: userEntity,
    entityGuid: userGuid
  });
}

export function fetchTestPersonDetails(personGuid) {
  return get("/testperson/TestPersonDetails", {
    testPersonGuid: personGuid
  });
}

export function fetchTestPersonProfileItems(userEntity, userGuid, personGuid) {
  return post(`/testperson/TestPersonProfileItems/${personGuid}`, {
    entityType: userEntity,
    entityGuid: userGuid
  });
}

export async function createNewTestPerson(userEntity, userGuid, personInfo) {
  let response = await post("/testperson/CreateNewTestPerson", {
    entityType: userEntity,
    entityGuid: userGuid,
    ...personInfo,
    age: personInfo.age && Number(personInfo.age)
  });
  return response.guid;
}

export async function saveByCreatingNewEditedPerson(
  personInfo,
  noUpdateFieldNames
) {
  let response = await post("/testperson/SaveByCreatingNewEditedPerson", {
    ...personInfo,
    age: personInfo.age && Number(personInfo.age),
    noUpdateFieldNames: noUpdateFieldNames
  });
  return response.guid;
}

export async function saveEditedPerson(personInfo, noUpdateFieldNames) {
  let response = await post("/testperson/SaveEditedPerson", {
    ...personInfo,
    age: personInfo.age && Number(personInfo.age),
    noUpdateFieldNames: noUpdateFieldNames
  });
  return response.guid;
}

export async function bulkDeletePerson(personGuidList) {
  let response = await post("/testperson/DeletePersonList", personGuidList);
  return response.guidList;
}

export async function downloadPersonListTemplate(userEntity, userGuid) {
  let response = await post("/testperson/GeneratePersonSheetTemplate", {
    entityType: userEntity,
    entityGuid: userGuid
  });
  return response.value;
}

export async function uploadPersonListFile(userEntity, userGuid, personFile) {
  let formData = new FormData();
  formData.append("personFile", personFile, personFile.name);
  let postUrl = `/testperson/UploadPersonSheet?entityType=${userEntity}&entityGuid=${userGuid}`;
  let response = await post(encodeURI(postUrl), formData);
  return response.guidList;
}
