var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppLoadingSpinner',{model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_vm._l((_vm.localFieldConfigList),function(field){return [(!field.valueRange.length && field.isVisible)?_c('div',{key:field.guid,staticClass:"d-flex align-end"},[_c('v-text-field',{attrs:{"label":field.fieldAlias,"hide-details":"auto","rules":_vm.buildTextFieldRules(field, true),"prefix":field.fieldName === 'testeeNum'
            ? _vm.buildTesteeNumPrefix(_vm.localPersonInfo.testeeNumPrefix)
            : '',"disabled":!_vm.isCreatingNew &&
            (_vm.localDisabledFields.includes(field.fieldName) ||
              _vm.securedFieldNameList.includes(field.fieldName))},model:{value:(_vm.localPersonInfo[field.fieldName]),callback:function ($$v) {_vm.$set(_vm.localPersonInfo, field.fieldName, $$v)},expression:"localPersonInfo[field.fieldName]"}}),(
          !_vm.isCreatingNew && _vm.securedFieldNameList.includes(field.fieldName)
        )?_c('AppTooltipBtn',{attrs:{"color":"primary","icon":"mdi-pencil","tooltip":("编辑" + (field.fieldAlias))},on:{"click":function($event){return _vm.startEditOneSecuredField(field)}}}):_vm._e()],1):_vm._e(),(!!field.valueRange.length && field.isVisible)?_c('v-select',{key:field.guid,attrs:{"label":field.fieldAlias,"hide-details":"auto","items":field.valueRange,"rules":field.isRequired ? _vm.fieldRules.required : [],"disabled":!_vm.isCreatingNew &&
          (_vm.localDisabledFields.includes(field.fieldName) ||
            _vm.securedFieldNameList.includes(field.fieldName))},on:{"change":function($event){return _vm.personFieldChanged(field.fieldName, _vm.localPersonInfo[field.fieldName])}},model:{value:(_vm.localPersonInfo[field.fieldName]),callback:function ($$v) {_vm.$set(_vm.localPersonInfo, field.fieldName, $$v)},expression:"localPersonInfo[field.fieldName]"}}):_vm._e()]}),_c('AppDialog',{attrs:{"persistent":"","size":"small","title":("编辑" + (_vm.editingSecuredField.fieldAlias)),"color":"primary","action-text":"确定修改"},on:{"confirm":function($event){return _vm.confirmEditOneSecuredField(
        _vm.editingSecuredField.fieldName,
        _vm.editingSecuredFieldValue
      )},"closed":_vm.closeEditOneSecuredField},model:{value:(_vm.isShowSecuredInfoEditDialog),callback:function ($$v) {_vm.isShowSecuredInfoEditDialog=$$v},expression:"isShowSecuredInfoEditDialog"}},[_c('v-text-field',{attrs:{"label":_vm.editingSecuredField.fieldAlias,"rules":_vm.buildTextFieldRules(_vm.editingSecuredField, false)},model:{value:(_vm.editingSecuredFieldValue),callback:function ($$v) {_vm.editingSecuredFieldValue=$$v},expression:"editingSecuredFieldValue"}})],1),_c('AppMessageBox',{attrs:{"title":"发生错误"},model:{value:(_vm.errorMsg),callback:function ($$v) {_vm.errorMsg=$$v},expression:"errorMsg"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }