<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card max-width="600px" class="mx-auto my-12 px-6 py-4" elevation="6">
      <v-form ref="searchForm">
        <v-expansion-panels flat v-model="personSearchPanelIndex">
          <v-expansion-panel>
            <v-expansion-panel-header class="mt-4 mb-6 text-h6">
              {{ searchCardTitle }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-select
                v-if="isDeptRequired"
                outlined
                dense
                class="mb-4"
                :label="label.deptTitle"
                hide-details="auto"
                :items="deptSelectItems"
                v-model="personSearch.deptGuid"
              ></v-select>
              <v-text-field
                outlined
                dense
                v-model="personSearch.testeeNum"
                :loading="isSearchPersonLoading"
                :label="`请输入${label.testeeNumTitle}`"
                clearable
              ></v-text-field>
              <div class="text-right">
                <v-btn
                  class="mr-4"
                  text
                  color="primary"
                  depressed
                  @click="switchToCreateMode"
                >
                  <v-icon class="mr-2">mdi-account-plus</v-icon>
                  新建
                </v-btn>
                <v-btn color="primary" depressed @click="searchPerson">
                  <v-icon class="mr-2">mdi-magnify</v-icon>
                  查找
                </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
      <v-form ref="infoForm" v-if="isCreateNewPerson || !!searchedPersonGuid">
        <v-divider></v-divider>
        <v-card-title class="mt-4 mb-6 text-h6">
          {{ infoCardTitle }}
        </v-card-title>
        <v-card-text v-if="isPersonInfoLoaded" class="px-8">
          <PersonInfoEditor
            :group-guid="groupGuid"
            v-model="personInfo"
            :disabled-fields="disabledPersonInfos"
            :is-creating-new="isCreateNewPerson"
            :no-update-fields="personNoUpdateFields"
            @update:no-update-fields="personNoUpdateFields = $event"
            :field-config-list="fieldConfigList"
            :dept-list="deptInfoList"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            class="px-8"
            @click="submitPersonInfo"
          >
            确认
            <v-icon dense class="ml-2">mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="app-card-text">
          个人信息经过保密处理，请放心填写
        </v-card-text>
      </v-form>
    </v-card>
    <AppDialog
      v-model="isShowExistingPersonDialog"
      size="small"
      :title="existingPersonDialogTitle"
      color="success"
      action-text="使用该用户"
      action-icon="mdi-check"
      @confirm="pickExistingPerson"
    >
      <v-radio-group
        class="px-8"
        mandatory
        v-model="existingPersonSelectedGuid"
      >
        <v-radio
          v-for="personToSelect in existingPersonList"
          :key="personToSelect.value"
          :value="personToSelect.value"
          :label="personToSelect.text"
          class="mb-4"
        >
        </v-radio>
      </v-radio-group>
      <template v-slot:action-ex>
        <v-btn text color="primary" @click="clickDialogCreatePerson">
          新建用户
        </v-btn>
      </template>
    </AppDialog>
    <AppDialog
      v-model="isShowIncompleteTestConfimDialog"
      title="未完成的测试"
      size="small"
      color="primary"
      action-text="继续测试"
      action-icon="mdi-lead-pencil"
      @confirm="continueIncompleteTests"
    >
      <p>发现有以下未完成的测试，要继续测试吗？</p>
      <ul>
        <li v-for="it in incompleteTestList" :key="it.guid">
          {{ it.lbDispName }}
        </li>
      </ul>
      <template v-slot:action-ex>
        <v-btn text color="primary" @click="continueWithNewTests">
          <v-icon class="mr-2">mdi-new-box</v-icon>
          开始新的测试
        </v-btn>
      </template>
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
    <AppMessageBox v-model="personNotFoundMsg" title="未找到该用户" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import PersonInfoEditor from "@/components/PersonInfoEditor";
import { mapGetters } from "vuex";
import {
  fetchIsContinuousTest,
  searchPersonInfo,
  getPersonInfoFromGuid
} from "@/api/sca";
import { fetchPrimaryPersonInput } from "@/api/user";
import { personInputTypes } from "@/utils/constants/user";
import { fetchDeptListFromGroup } from "@/api/dept";
import {
  createNewTestPerson,
  saveByCreatingNewEditedPerson
} from "@/api/person";
import { getTestGroupFieldConfig } from "@/api/fieldConfig";
import { fetchIncompleteTestList } from "@/api/sca";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog,
    PersonInfoEditor
  },

  data() {
    return {
      // loading
      isLoading: false,
      isSearchPersonLoading: false,
      // meta data
      isDeptRequired: false,
      isContinuousTest: false,
      label: {
        userDispName: "",
        deptTitle: "",
        testeeNumTitle: ""
      },
      fieldConfigList: [],
      deptInfoList: [],
      // message box
      personNotFoundMsg: "",
      errorMsg: "",
      // search
      personSearch: {
        deptGuid: "",
        testeeNum: ""
      },
      emptyPersonSearch: {
        deptGuid: "",
        testeeNum: ""
      },
      searchedPersonGuid: "",
      isCreateNewPerson: false,
      personSearchPanelIndex: 0,
      // person info
      personInfo: {
        guid: "",
        name: "",
        gender: "",
        age: "",
        eduLevel: "",
        marriage: "",
        job: "",
        deptGuid: "",
        deptName: "",
        testeeNum: "",
        testeeNumPrefix: "",
        addInfo1: "",
        addInfo2: "",
        addInfo3: "",
        addInfo4: "",
        addInfo5: "",
        addInfo6: ""
      },
      emptyPersonInfo: {
        guid: "",
        name: "",
        gender: "",
        age: "",
        eduLevel: "",
        marriage: "",
        job: "",
        deptGuid: "",
        deptName: "",
        testeeNum: "",
        testeeNumPrefix: "",
        addInfo1: "",
        addInfo2: "",
        addInfo3: "",
        addInfo4: "",
        addInfo5: "",
        addInfo6: ""
      },
      disabledPersonInfos: [],
      personNoUpdateFields: [],
      // person selection/confirm
      isPersonInfoLoaded: false,
      isShowExistingPersonDialog: false,
      existingPersonDialogTitle: "",
      existingPersonList: [],
      existingPersonSelectedGuid: "",
      // incomplete test
      isShowIncompleteTestConfimDialog: false,
      incompleteTestList: []
    };
  },

  computed: {
    ...mapGetters({
      userEntity: "sca/userEntity",
      userGuid: "sca/userGuid",
      groupGuid: "sca/groupGuid"
    }),
    searchCardTitle() {
      return "查找或新建测量用户";
    },
    infoCardTitle() {
      return this.searchedPersonGuid ? "确认个人信息" : "新建测量用户";
    },
    deptSelectItems() {
      return this.deptInfoList.map(dept => {
        return {
          text: dept.text,
          value: dept.value
        };
      });
    }
  },

  watch: {
    isCreateNewPerson(newVal) {
      // 切换成新建模式时，清空
      if (newVal) {
        this.resetPersonInfo();
        this.resetPersonInfoSearch();
      }
    },
    isShowExistingPersonDialog(newVal) {
      if (!newVal) {
        this.existingPersonDialogTitle = "";
        this.existingPersonList = [];
        this.existingPersonSelectedGuid = "";
      }
    },
    searchedPersonGuid(newVal) {
      if (newVal) {
        return this.getPersonInfo();
      }
    }
  },

  methods: {
    async getPrimaryPersonInput() {
      try {
        this.isLoading = true;
        let primaryPersonInput = await fetchPrimaryPersonInput(this.userGuid);
        // 默认为搜索模式，入宫时create，则切换到新建模式
        if (primaryPersonInput === personInputTypes.create.value) {
          this.switchToCreateMode();
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async getIsContinuousTest() {
      try {
        this.isLoading = true;
        this.isContinuousTest = await fetchIsContinuousTest(this.groupGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    switchToCreateMode() {
      this.isCreateNewPerson = true;
      this.isShowExistingPersonDialog = false;
      this.personSearchPanelIndex = null;
      this.disabledPersonInfos = [];
    },
    switchToSearchMode() {
      this.isCreateNewPerson = false;
      this.personSearchPanelIndex = 0;
      this.disabledPersonInfos = ["deptGuid", "testeeNum"];
    },
    resetPersonInfoSearch() {
      Object.assign(this.personSearch, this.emptyPersonSearch);
    },
    resetPersonInfo() {
      this.searchedPersonGuid = "";
      Object.assign(this.personInfo, this.emptyPersonInfo);
      this.$refs.infoForm && this.$refs.infoForm.resetValidation();
    },
    async searchPerson() {
      try {
        this.isSearchPersonLoading = true;
        this.switchToSearchMode();
        let searchedOutList = await searchPersonInfo(this.userGuid, {
          deptGuid: this.personSearch.deptGuid,
          testeeNum: this.personSearch.testeeNum
        });
        // testeeNum 有可能不是唯一号，能搜出多个结果
        if (!searchedOutList || !searchedOutList.length) {
          this.personNotFoundMsg = "请点击“新建”按钮新一个建测量用户";
          this.resetPersonInfo();
        } else if (searchedOutList.length > 1) {
          this.isShowExistingPersonDialog = true;
          this.existingPersonDialogTitle =
            "找到多个用户，请选择一个或新建用户进行答题";
          this.existingPersonList = searchedOutList;
        } else {
          this.searchedPersonGuid = searchedOutList[0].value;
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isSearchPersonLoading = false;
    },
    async getPersonInfo() {
      try {
        this.isLoading = true;
        let retPersonInfo = await getPersonInfoFromGuid(
          this.searchedPersonGuid
        );
        Object.assign(this.personInfo, retPersonInfo);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async getGroupFieldConfig() {
      try {
        this.isLoading = true;
        this.fieldConfigList = await getTestGroupFieldConfig(this.groupGuid);
        let deptConfig = this.fieldConfigList.find(
          fc => fc.fieldName === "deptGuid"
        );
        let testeeNumConfig = this.fieldConfigList.find(
          fc => fc.fieldName === "testeeNum"
        );
        this.label.deptTitle = deptConfig ? deptConfig.fieldAlias : "";
        this.label.testeeNumTitle = testeeNumConfig
          ? testeeNumConfig.fieldAlias
          : "";
        this.isDeptRequired = deptConfig ? deptConfig.isRequired : false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async getDeptSelectList() {
      try {
        this.isLoading = true;
        this.deptInfoList = await fetchDeptListFromGroup(this.groupGuid);
        // 如果只有一个 dept，自动选上
        if (this.deptInfoList.length === 1) {
          let onlyDept = this.deptInfoList[0];
          this.personSearch.deptGuid = onlyDept.value;
          this.emptyPersonSearch.deptGuid = onlyDept.value;
          this.emptyPersonInfo.deptGuid = onlyDept.value;
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async submitPersonInfo() {
      let isValid = this.$refs.infoForm.validate();
      if (isValid) {
        this.isLoading = true;
        // 判断是查找还是新建用户
        if (this.searchedPersonGuid) {
          let personGuid = await this.updateExistingPerson();
          await this.setPersonAndMoveToNextPage(personGuid);
        } else {
          await this.checkPersonDuplicate();
        }
        this.isLoading = false;
      }
    },
    async updateExistingPerson() {
      try {
        this.isLoading = true;
        let editedNewPersonGuid = await saveByCreatingNewEditedPerson(
          {
            ...this.personInfo,
            guid: this.searchedPersonGuid
          },
          this.personNoUpdateFields
        );
        this.searchedPersonGuid = editedNewPersonGuid;
        return editedNewPersonGuid;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async checkPersonDuplicate() {
      try {
        // 判断是否存在该用户
        let existingPerson = await searchPersonInfo(this.userGuid, {
          deptGuid: this.personInfo.deptGuid,
          testeeNum: this.personInfo.testeeNum
        });
        if (existingPerson && existingPerson.length) {
          this.isShowExistingPersonDialog = true;
          this.existingPersonDialogTitle = `该${this.label.testeeNumTitle}已存在，是否要使用已存在的用户，或者点击新建用户。`;
          this.existingPersonList = existingPerson;
        } else {
          await this.createNewPerson();
        }
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async createNewPerson() {
      try {
        let personGuid = await createNewTestPerson(
          this.userEntity,
          this.userGuid,
          { ...this.personInfo }
        );
        await this.setPersonAndMoveToNextPage(personGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async setPersonAndMoveToNextPage(personGuid) {
      if (personGuid) {
        this.$store.dispatch("sca/setPersonGuid", personGuid);
        this.$store.dispatch("sca/setDeptGuid", this.personInfo.deptGuid);
        this.$store.dispatch("sca/setDeptName", this.personInfo.deptName);
        // 查找是否有未做完的测试
        this.incompleteTestList = await fetchIncompleteTestList({
          userGuid: this.userGuid,
          groupGuid: this.groupGuid,
          personGuid
        });
        if (this.incompleteTestList && this.incompleteTestList.length) {
          this.isShowIncompleteTestConfimDialog = true;
        } else {
          this.moveToNextPage();
        }
      }
    },
    moveToNextPage() {
      // 如果是连续测量，则进入连续答题页面
      // 如果不是连续测量，则进入量表选择页面
      if (this.isContinuousTest) {
        this.$router.push({ name: "scastepper" });
      } else {
        this.$router.push({ name: "scaselect" });
      }
    },
    // -------------- Dialog -----------------
    pickExistingPerson() {
      this.searchedPersonGuid = this.existingPersonSelectedGuid;
      this.isShowExistingPersonDialog = false;
    },
    async clickDialogCreatePerson() {
      if (this.isCreateNewPerson) {
        this.isLoading = true;
        await this.createNewPerson();
        this.isLoading = false;
      } else {
        this.switchToSearchMode();
      }
    },
    continueIncompleteTests() {
      let testLbList = this.incompleteTestList.map((it, index) => {
        return {
          text: it.lbDispName,
          value: it.lbGuid,
          order: index
        };
      });
      this.$store.dispatch("sca/setTestLbList", testLbList);
      this.$router.push({ name: "scastepper" });
    },
    continueWithNewTests() {
      this.moveToNextPage();
    }
  },

  destroyed() {
    this.isPersonInfoLoaded = false;
  },

  async created() {
    this.getPrimaryPersonInput();
    this.getIsContinuousTest();
    await this.getGroupFieldConfig();
    // 如果只有一个 dept，则可以自动选上
    await this.getDeptSelectList();
    this.resetPersonInfo();
    this.isPersonInfoLoaded = true;
  }
};
</script>

<style lang="scss" scoped>
.app-card-text {
  color: rgba(0, 0, 0, 0.6);
}
</style>
